import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login.vue'
import splash from '../views/splash.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'splash',
    component: splash
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let authStatusCookie = window.localStorage.getItem('whitechoc');
  let now = new Date();
  if (to.name == 'Home' && (authStatusCookie == 'null' || authStatusCookie == null)) {
    next({
      name: 'splash'
    })
  } else if (to.name == 'Home' && (JSON.parse(authStatusCookie).value !== "8u237d902jd")) {
    console.log('Cookie Present but wrong val');
    next({
      name: 'splash'
    })
  } else if (to.name == 'Home' && JSON.parse(authStatusCookie).expiry < now.getTime()) {
    console.log('Cookie present but expired');
    window.localStorage.removeItem('whitechoc');
    //window.localStorage.removeItem('chocchip');
    next({
      name: 'splash'
    })
  } else {
    next()
  }
})

export default router