<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    /*
    videojs.Vhs.xhr.beforeRequest = function(options) {
      if (!options.headers){
            options.headers = {}
        }
        options.headers["Authorization"] = "Basic b3NiYXBpMDE6MjM5OGRuZmowOTJ1M2pz"

      return options;
    };
    */
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        //console.log(this);
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
.video-js.vjs-static-controls .vjs-control-bar {
  margin-bottom: -5px;
}
</style>

