<template>
  <v-app>
    <v-main class="lightApp" v-bind:class="{ darkApp:(this.darkMode) }">
      <router-view v-bind:serverAddressProp="serverAddressProp" v-bind:darkMode="darkMode" v-on:infocus="setDarkMode()"/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: function () {
    return {
      serverAddressProp: window.location.origin + window.location.pathname + "api", //production
      darkMode: false,
    };
  },
  beforeMount() {
    this.setDarkMode();
  },
  methods: {
    setDarkMode: function() {
      var hours = new Date().getHours();
      if (hours >= 18 || hours <= 7) {
        this.darkMode = true;
        console.log("dark mode")
      } else {
        this.darkMode = false;
        console.log("light mode")
      }
    }
  }
};
</script>

<style scoped>

.lightApp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(232, 232, 232);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;
  position: fixed;
  width: 100vw;
  height: calc(100vh + 100px);
}

.darkApp {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(24, 24, 24);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;
  position: fixed;
  width: 100vw;
  height: calc(100vh + 100px);
}

</style>

<style>
::-webkit-scrollbar {
  display: none;
  width: 0; /* Remove scrollbar space */
}
</style>