<template>
  <div>
    <v-dialog
      v-model="loginDialog"
      persistent
      width="500"
      overlay-opacity="0"
      style="overflow: none"
    >
      <v-card>
        <v-toolbar flat dense color="grey lighten-2">
          <img class="logo1" src="../assets/NEP-Small-BW.png" />
        </v-toolbar>

        <form class="pa-3 ma-0" style="overflow: none">
          <v-text-field
            v-model="usernameBuffer"
            :rules="rules"
            label="Username"
          ></v-text-field>
          <v-text-field
            id="passwordInput"
            v-model="passwordBuffer"
            :rules="rules"
            type="password"
            label="Password"
          ></v-text-field>
          <v-btn color="primary" class="pa-1" text @click="signIn()">
            Log In
          </v-btn>
        </form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="red darken-4">
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "login",
  data: function () {
    return {
      loginDialog: true,
      usernameBuffer: "",
      passwordBuffer: "",
      max: 20,
      allowSpaces: false,
      snackText: "",
      snackbar: false,
    };
  },
  props: {
    serverAddressProp: undefined,
  },
  mounted: function() {
    let input = document.getElementById("passwordInput");
    input.addEventListener("keyup", (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.signIn();
      }
    })
  },
  methods: {
    signIn: function () {
      console.log("Signin");
      let url = this.serverAddressProp + "/usertest";
      axios
        .post(
          url,
          {
            user: {
              username: this.usernameBuffer,
              password: this.passwordBuffer,
            },
          },
          {
            auth: {
              username: "osbapi01",
              password: "2398dnfj092u3js",
            },
          }
        )
        .then((response) => {
          if (response.data.status != null) {
            if (response.data.status === "authenticated") {
              console.log("User Authenticated");
              let now = new Date();
              let tempcookie = {
                value: '8u237d902jd',
                expiry: now.getTime() + 360000
              }
              //console.log("Now: " + now.getTime());
              //console.log("New Cookie Expiry: " + (now.getTime() + 360000));
              window.localStorage.setItem('whitechoc', JSON.stringify(tempcookie));
              window.localStorage.setItem('chocchip', response.data.cookie);
              this.$router.push("/home");
            } else {
              console.log("User Forbidden");
              this.snackText = "Incorrect username/password";
              this.snackbar = true;
              let now = new Date();
              let tempcookie = {
                value: '72he9kd48x2',
                expiry: now.getTime() + 360000
              }
              window.localStorage.setItem('whitechoc', JSON.stringify(tempcookie));
              this.loginDialog = true;
              this.passwordBuffer = "";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    rules() {
      const rules = [];

      if (this.max) {
        const rule = (v) =>
          (v || "").length <= this.max ||
          `A maximum of ${this.max} characters is allowed`;

        rules.push(rule);
      }

      if (!this.allowSpaces) {
        const rule = (v) =>
          (v || "").indexOf(" ") < 0 || "No spaces are allowed";

        rules.push(rule);
      }

      return rules;
    },
  },
};
</script>

<style scoped>
.logo1 {
  height: 30px;
  margin-left: -8px;
}
</style>
