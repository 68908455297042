<template>
  <div class="splashDiv"></div>
</template>

<script>
import axios from "axios";
export default {
  name: "splash",
  data: function () {
    return {
      isAuthenticated: false,
    };
  },
  props: {
    serverAddressProp: undefined,
  },
  mounted() {
    this.checkCookie();
  },
  methods: {
    checkCookie: function () {
      let authCookie = window.localStorage.getItem('chocchip')
      if (authCookie == 'null' || authCookie == null) {
        this.$router.push("/login");
      } else {
        this.testCookie(authCookie);
      }
    },
    testCookie: function (val) {
      let url = this.serverAddressProp + "/cookietest";
      axios
        .post(
          url,
          {
            val: val,
          },
          {
            auth: {
              username: "osbapi01",
              password: "2398dnfj092u3js",
            },
          }
        )
        .then((response) => {
          if (response.data.status != null) {
            if (response.data.status === "authenticated") {
              console.log("Cookie Authenticated");
              let now = new Date();
              let tempcookie = {
                value: '8u237d902jd',
                expiry: now.getTime() + 360000,
                option1: response.data.option1
              }
              window.localStorage.setItem('whitechoc', JSON.stringify(tempcookie));
              this.$router.push("/home");
            } else {
              let now = new Date();
              let tempcookie = {
                value: '72he9kd48x2',
                expiry: now.getTime() + 360000
              }
              window.localStorage.setItem('whitechoc', JSON.stringify(tempcookie));
              this.$router.push("/login");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.splashDiv {
  background-color: rgb(0, 0, 0);
  height: 100vh;
  width: 100vw;
}
</style>
