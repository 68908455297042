<template>
  <div>
    <v-container fluid pa-0>
      <div class="banner text-h6 white--text" v-if="this.banner !== null">
        {{ banner }}
      </div>

      <v-alert
        type="error"
        class="ma-5"
        icon="mdi-wifi-alert"
        v-if="this.connectionWarning"
      >
      Poor connection
      </v-alert>

      <v-row no-gutters style="height: 70vh">
        <v-col cols="12" align="center">
          <div id="centerDiv">
            <transition name="fade">
              <div
                class="rainbowRing"
                v-if="status == 'motion' && this.banner == null"
              ></div>
            </transition>
            <transition name="fade">
              <div
                class="staticRainbowRing"
                v-if="status == 'requested' && this.banner == null"
              ></div>
            </transition>
            <transition name="fade">
              <div
                class="greenRing"
                v-if="status == 'open' && this.banner == null"
              ></div>
            </transition>


            <div class="buttsDiv">
              <v-btn
                icon
                class="butt1"
                x-large
                :color="iconColor"
                @click="cameraWindow1 = true"
              >
                <v-icon>mdi-video</v-icon>
              </v-btn>

              <v-btn
                icon
                class="butt2"
                x-large
                :color="iconColor"
                @click="cameraWindow2 = true"
              >
                <v-icon>mdi-video</v-icon>
              </v-btn>

              <v-btn
                icon
                class="butt3"
                x-large
                :color="iconColor"
                @click="cameraWindow3 = true"
              >
                <v-icon>mdi-video</v-icon>
              </v-btn>

              <v-btn
                icon
                class="butt4"
                x-large
                :color="bulbcolor"
                @click="lightsClicked()"
              >
                <v-icon>{{ bulbicon }}</v-icon>
              </v-btn>
            </div>
            <div
              v-if="this.banner === null"
              class="mainButt"
              @click="clicked()"
              v-bind:class="{ noClick: status == 'open' }"
            ></div>
            <h1
              @click="clicked()"
              v-if="this.banner === null"
              v-bind:class="{ noClick: status == 'open' }"
            >
              {{ buttMsg }}
            </h1>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="helpDialog" max-width="400" :dark="darkMode">
        <v-card>
          <v-card-title class="text-h5">About </v-card-title>

          <v-card-text class="mt-4">
            NEP Gate Remote <br>
            Version {{ version }} <br>
            For support contact Jack Woodgate
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="helpDialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="logoutDialog" max-width="310" :dark="darkMode">
        <v-card>
          <v-card-title class="text-subtitle-1">
            Are you sure you wish to logout?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="logoutDialog = false">
              No
            </v-btn>

            <v-btn color="primary darken-1" text @click="logout()">
              Yes Logout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="cameraWindow1" max-width="800" :dark="darkMode">
        <v-card>
          <v-card-title class="text-subtitle-1 ml-n1">
            Carpark Cam 1
          </v-card-title>
          <VideoPlayer
            v-if="cameraWindow1"
            class="streamgrab d-flex justify-center"
            :options="videoOptions"
          >
          </VideoPlayer>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              class="ml-n1"
              text
              @click="cameraWindow1 = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="cameraWindow2" max-width="800" :dark="darkMode">
        <v-card>
          <v-card-title class="text-subtitle-1 ml-n1">
            Carpark Cam 2
          </v-card-title>
          <VideoPlayer
            v-if="cameraWindow2"
            class="streamgrab d-flex justify-center"
            :options="videoOptions2"
          >
          </VideoPlayer>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              class="ml-n1"
              text
              @click="cameraWindow2 = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="cameraWindow3" max-width="800" :dark="darkMode">
        <v-card>
          <v-card-title class="text-subtitle-1 ml-n1">
            Carpark Cam 3
          </v-card-title>
          <VideoPlayer
            v-if="cameraWindow3"
            class="streamgrab d-flex justify-center"
            :options="videoOptions3"
          >
          </VideoPlayer>
          <v-card-actions>
            <v-btn
              color="primary darken-1"
              class="ml-n1"
              text
              @click="cameraWindow3 = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container class="footer1 d-inline-flex justify-center mb-3">
        <v-btn
          icon
          @click.stop="helpDialog = true"
          class="ml-3 mr-3"
          :color="iconColor"
        >
          <v-icon>mdi-help</v-icon>
        </v-btn>

        <v-btn
          icon
          @click.stop="logoutDialog = true"
          class="ml-3 mr-3"
          :color="iconColor"
        >
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VideoPlayer from "../components/VideoPlayer.vue";

export default {
  name: "Home",
  components: {
    VideoPlayer,
  },
  data: function () {
    return {
      outOfFocus: false,
      pedestrianMode: false,
      banner: null,
      connectionWarning: false,
      connectionWarningTimeout: null,
      getTimer: null, 
      plop: new Audio("/plop1.mp3"),
      version: "3.0.1",
      logoutDialog: false,
      helpDialog: false,
      cameraWindow1: false,
      cameraWindow2: false,
      cameraWindow3: false,
      status: "closed",
      lights: false,
      chocchip: "",
      t1: null,
      videoOptions: {
        autoplay: true,
        controls: true,
        playsinline: true,
        fluid: true,
        bigPlayButton: false,
        poster: "/streamloading1.jpg",
        sources: [
          {
            //src: "/stream1/stream.m3u8",
            src: "/hls8hn287h3nc878hm0ijsi/stream/cam1/channel/0/hls/live/index.m3u8"
          },
        ],
      },
      videoOptions2: {
        autoplay: true,
        controls: true,
        playsinline: true,
        fluid: true,
        bigPlayButton: false,
        poster: "/streamloading2.jpg",
        sources: [
          {
            //src: "/stream2/stream.m3u8",
            src: "/hls8hn287h3nc878hm0ijsi/stream/cam2/channel/0/hls/live/index.m3u8",
            type: "application/x-mpegURL",
          },
        ],
      },
      videoOptions3: {
        autoplay: true,
        controls: true,
        playsinline: true,
        fluid: true,
        bigPlayButton: false,
        poster: "/streamloading2.jpg",
        sources: [
          {
            //src: "/stream3/stream.m3u8",
            src: "/hls8hn287h3nc878hm0ijsi/stream/cam3/channel/0/hls/live/index.m3u8",
            type: "application/x-mpegURL"
          },
        ],
      },
    };
  },
  props: {
    serverAddressProp: undefined,
    darkMode: Boolean
  },
  beforeMount() {
    this.status = "closed";
  },
  mounted() {
    this.apiGet();
    if (this.getTimer == null) {
      this.getTimer = setInterval(this.apiGet, 3000);
    }
    this.chocchip = window.localStorage.getItem("chocchip");
    this.whitechoc = JSON.parse(window.localStorage.getItem("whitechoc"));
    this.detectFocusChange();
  },
  methods: {
    detectFocusChange() {
        let inView = false;
        const onWindowFocusChange = (e) => {
            if ({ focus: 1, pageshow: 1 }[e.type]) {
                if (inView) return;
                this.tabFocus = true;
                inView = true;
                if (this.outOfFocus) {
                  if (this.$route.path == "/") {
                    this.getInitialData()
                    this.getLogData();
                  } else if (this.$route.path == "/inspect") {
                    this.getTrunkDefinitions();
                    this.getMatrixList();
                  }
                  console.log("In Focus")
                  this.status = "closed";
                  this.apiGet();
                  this.$emit("infocus");
                }
            } else if (inView) {
                this.tabFocus = !this.tabFocus;
                inView = false;
                console.log("Out Of Focus")
                this.outOfFocus = true;
            }
        };
        window.addEventListener('focus', onWindowFocusChange);
        window.addEventListener('blur', onWindowFocusChange);
        window.addEventListener('pageshow', onWindowFocusChange);
        window.addEventListener('pagehide', onWindowFocusChange);
    },
    logout: function () {
      window.localStorage.removeItem("whitechoc");
      window.localStorage.removeItem("chocchip");
      this.$router.replace("/login");
    },
    clicked: function () {
      if (this.status == "closed") {
        if (this.whitechoc !== null && this.whitechoc !== "null") {
          if ("option1" in this.whitechoc) {
            if (this.whitechoc.option1 == true) {
              let path = "/fart" + this.getRandomInt(1, 4) + ".mp3";
              let fart = new Audio(path);
              fart.play();
            }
          }
        }
        console.log("Sent Open Gate CMD..");
        this.status = "requested";
        this.apiCmd("opengate01");
        let date = new Date();
        if (date.getHours() > 17 || date.getHours() < 7) {
          this.lights = true;
          this.apiCmd("lights-true");
          console.log("Lights Triggered With Gate");
        }
      } else if (this.status == "motion") {
        if (this.whitechoc !== null && this.whitechoc !== "null") {
          if ("option1" in this.whitechoc) {
            if (this.whitechoc.option1 == true) {
              let path = "/fart" + this.getRandomInt(1, 4) + ".mp3";
              let fart = new Audio(path);
              fart.play();
            }
          }
        }
        console.log("Sent Open Gate CMD..");
        this.status = "requested";
        this.apiCmd("opengate01");
      } else if (this.status == "open") {
        if (this.whitechoc !== null && this.whitechoc !== "null") {
          if ("option1" in this.whitechoc) {
            if (this.whitechoc.option1 == true) {
              let path = "/fart" + this.getRandomInt(1, 4) + ".mp3";
              let fart = new Audio(path);
              fart.play();
            }
          }
        }
        console.log("Sent Open Gate CMD..");
        this.status = "requested";
        this.apiCmd("opengate01");
      }
    },
    lightsClicked: function () {
      if (this.lights === true) {
        this.lights = false;
        this.apiCmd("lights-false");
      } else if (this.lights === false) {
        this.lights = true;
        this.apiCmd("lights-true");
      }
    },
    getRandomInt: function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    apiCmd: function (cmd) {
      let url = this.serverAddressProp + "/control";
      axios
        .post(
          url,
          {
            command: cmd,
            chocchip: this.chocchip,
          },
          {
            auth: {
              username: "osbapi01",
              password: "2398dnfj092u3js",
            },
          }
        )
        .then((response) => {
          if (response.data.gateStatus != null) {
            this.status = response.data.gateStatus;
          }
          if (response.data.lightsStatus != null) {
            this.lights = response.data.lightsStatus;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    apiGet: function () {
      this.setConnectionWarning();
      let url = this.serverAddressProp + "/data";
      axios
        .get(url, {
          auth: {
            username: "osbapi01",
            password: "2398dnfj092u3js",
          },
        })
        .then((response) => {
          if (response.data.gateStatus != null) {
            this.status = response.data.gateStatus;
            this.clearConnectionWarning();
          }
          if (response.data.lightsStatus != null) {
            this.lights = response.data.lightsStatus;
          }
          if ("banner" in response.data) {
            this.banner = response.data.banner;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.connectionWarning = true;
        });
    },
    setConnectionWarning: function() {
      if (this.connectionWarningTimeout == null) {
        this.connectionWarningTimeout = setTimeout(() => {
          this.connectionWarning = true;
        }, 2000);
      }
    },
    clearConnectionWarning: function() {
      if (this.connectionWarningTimeout !== null) {
        clearTimeout(this.connectionWarningTimeout);
      }
      this.connectionWarning = false;
      this.connectionWarningTimeout = null;
    }
  },
  computed: {
    buttMsg: function () {
      let string = "Tap to open";
      if (this.status == "motion") {
        string = "Gate in motion!";
      } else if (this.status == "open") {
        string = "Gate is open";
      } else if (this.status == "requested") {
        string = "Requested";
      }
      return string;
    },
    bulbcolor: function () {
      let color = this.iconColor;
      if (this.lights === true) {
        color = "yellow darken-3";
      }
      return color;
    },
    bulbicon: function () {
      let icon = "mdi-lightbulb-outline";
      if (this.lights === true) {
        icon = "mdi-lightbulb";
      }
      return icon;
    },
    iconColor: function() {
      if (this.darkMode) {
        return "grey";
      } else {
        return "";
      }
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#centerDiv {
  width: 75%;
  max-width: 700px;
  height: 40vw;
  max-height: 380px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mainButt {
  border-radius: 100%;
  background: linear-gradient(#aaaaaa, #6a6a6a);
  opacity: 1;
  width: 270px;
  height: 270px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.banner {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.buttsDiv {
  width: 270px;
  height: 270px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rainbowRing {
  border-radius: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 278px;
  height: 278px;
  border-width: 4px;
  border-style: solid;
  border-top-color: #f7941d;
  border-left-color: #60bb46;
  border-bottom-color: #0093d8;
  border-right-color: #ac208e;
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.staticRainbowRing {
  border-radius: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 278px;
  height: 278px;
  border-width: 4px;
  border-style: solid;
  border-top-color: #f7941d;
  border-left-color: #60bb46;
  border-bottom-color: #0093d8;
  border-right-color: #ac208e;
}

.greenRing {
  border-radius: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 278px;
  height: 278px;
  border-width: 4px;
  border-style: solid;
  border-color: #60bb46;
}

h1 {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-weight: 10;
  cursor: pointer;
}

.footer1 {
  height: 10vh;
  width: 100%;
  max-width: 100%;
  position: fixed;
  bottom: 0;
}

.butt1 {
  position: relative;
  top: 280px;
  left: -50px;
}

.butt2 {
  position: relative;
  top: 330px;
  left: -20px;
}

.butt3 {
  position: relative;
  top: 330px;
  left: 20px;
}

.butt4 {
  position: relative;
  top: 280px;
  left: 50px;
}

.streamgrab {
  width: 99%;
  margin: auto;
  padding: 0px;
}

.noClick {
  cursor: unset;
}
</style>
